.App {
  .backBtn_left {
    position: fixed;
    left: 10px;
    top: 10px;
    // width: 60px;
    // height: 60px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      transform: scale(1.1);
      transition: all 0s;
    }

    img {
      width: 90%;
    }
  }

  .backBtn_right {
    position: fixed;
    left: 10px;
    top: 10px;
    // width: 60px;
    // height: 60px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      transform: scale(1.1);
      transition: all 0s;
    }

    img {
      width: 90%;
    }
  }
}

@media screen and (max-width: 768px) {
  .App {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .backBtn_left {
      position: fixed;
      left: 10px;
      top: 10px;
      width: 60px;
      height: 60px;
      cursor: pointer;
      z-index: 1;

      &:hover {
        transform: scale(1.1);
        transition: all 0s;
      }

      img {
        width: 90%;
      }
    }

    .backBtn_right {
      position: fixed;
      left: 10px;
      top: 10px;
      width: 60px;
      height: 60px;
      cursor: pointer;
      z-index: 1;

      &:hover {
        transform: scale(1.1);
        transition: all 0s;
      }

      img {
        width: 90%;
      }
    }
  }
}

@media screen and (max-height: 400px) {
}
