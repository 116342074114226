$mainColor: #65822E;
$firstColor: #364618;
$secondColor: #475C22;
$thirdColor: #65822E;
$fourthColor: #FDE780;
$fifthhColor: #F6FCAA;
$gradient1: #CE8F31;
$gradient2: #F6E493;
$gradient3: #EFD45A;

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  // margin: 0;
  font-family: 'Montserrat';
  // font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(var(--mainColor3) 50px, var(--firstColor3) 70%);
  width: 100vw;
  height: 100vh;
}

html {
  scroll-behavior: smooth;
  background: var(--firstColor3);
}

:root{ 
  --mainColor: #{$mainColor};
  --firstColor: #{$firstColor};
  --secondColor: #{$secondColor};
  --thirdColor: #{$thirdColor};
  --fourthColor: #{$fourthColor};
  --fifthhColor: #{$fifthhColor};
  --gradient1: #{$gradient1};
  --gradient2: #{$gradient2};
  --gradient3: #{$gradient3};

  --mainColor2: #009056;
  --firstColor2: #03623e;
  --btnHoverColor2: #03623e;
  --campusGradient: #fff;

  --mainColor3: #41a5dd;
  --firstColor3: #124e7b;
  --btnHoverColor3: #124e7b;
  --seaviewGradient11: #41a5dd;
  --seaviewGradient12: #124e7b;
  --seaviewGradient21: #a8d8b7;
  --seaviewGradient22: #09769b;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 92%;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }

  body {
    -moz-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
